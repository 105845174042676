import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MainPageComponent from "../components/main-page/main-page.component"

const IndexPage = () => {

  return (
    <Layout>
        <MainPageComponent />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
